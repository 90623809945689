import { DisciplinaryCase } from "@api/disciplinary/model";
import { Result } from "@api/session/model";
import { Season } from "src/app/modules/league/models/season";
import { Session } from "src/app/modules/league/models/session";
import { Track } from "src/app/modules/league/models/track";

export interface Event {
  id: number;
  seasonid: number;
  name: string;
  trackid: number;
  prequalifications: boolean;
  preqStart: Date;
  preqStop: Date;
  preqquery: string;
  replays: string;
  serverpassword: string;
  order: number;
  showinclassifications: boolean;
  countinvalidlaps: boolean;
  protestDeadlineInHours: number;
  protestResponseInHours: number;
  videourl: string;
  videosrc: string;
  videodescription: string;
}

export interface EventDetailed extends Event {
  season: Partial<Season>;
  track: Track;
  sessions: Session[];

  eventStart: Date;
  eventStop: Date;
}

export enum EventsSource {
  USER = "USER",
  ADMIN = "ADMIN",
}

export interface PreqResult {
  userid: number;
  userName: string;
  startnumber: number;
  teamid: number;
  teamName: string;
  time: number;
  presence: boolean;
  driverPrequalified: string;

  steamId: number;
}

export interface PreqResultTable extends PreqResult {
  position: number;
  splitassignment: boolean;
}

export interface EventResults {
  results: ResultTable[];
  protests: DisciplinaryCase[];
}

export interface ResultTable extends Result {
  flHolder: boolean;
}

export interface EventStandings {
  points: number;
  split: number;
  eventid: number;
  userid: number;
  firstname: string;
  lastname: string;
  drivername: string;
  startnumber: number;
  teamname: string;
  icon: string;
}

export interface EventTvStandings extends EventStandings {
  position: number;
}
